import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils, BigNumber } from 'ethers'
import { call, makeUseBalance } from './hooks'
import daskvault from './abis/TombVault.json'
import { vaultContractAddress } from '../config'


const vaultInterface = new utils.Interface(daskvault.abi)
const vaultContract = new Contract(vaultContractAddress, vaultInterface)

const vaultCall = call(vaultInterface, vaultContractAddress)

const useVaultBalance = makeUseBalance(vaultCall)

const useAccountStakedTokens = (address) => {
    const [tokens] = useContractCalls([
        address && vaultCall('tokensOfOwner', [address])
    ]).filter(Boolean)
    return tokens && tokens?.["ownerTokens"] && tokens["ownerTokens"].map((t) => BigNumber.isBigNumber(t) ? t.toNumber() : t)
}

const useEarningInfo = (tokenids, balanceValid) => {
    console.log("unclamiedEarnings", tokenids, balanceValid)
    const calls = (balanceValid && tokenids && tokenids.length > 0) ? [vaultCall('unclamiedEarnings', [tokenids])] : []
    const [earningInfo] = useContractCalls([
        ...calls
    ]).filter(Boolean)
    // console.log("unclamiedEarnings", earningInfo)
    return earningInfo?.[0]
}

const useStakeTokens = () => {
    const fn = useContractFunction(vaultContract, 'stake')
    return { stake: fn.send, stakeState: fn.state, stakeEvents: fn.events }
}

const useUnstakeTokens = () => {
    const fn = useContractFunction(vaultContract, 'unstake')
    return { unstake: fn.send, unstakeState: fn.state, unstakeEvents: fn.events }
}
const useClaimTokens = () => {
    const fn = useContractFunction(vaultContract, 'claim')
    return { claim: fn.send, claimState: fn.state, claimEvents: fn.events }
}



export { useVaultBalance, useAccountStakedTokens, useEarningInfo, useStakeTokens, useUnstakeTokens, useClaimTokens }