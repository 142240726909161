import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { call, makeUseBalance } from './hooks'
import tomb from './abis/Tombstone.json'
import { tombContractAddress, vaultContractAddress } from '../config'

const tombInterface = new utils.Interface(tomb.abi)
const tombContract = new Contract(tombContractAddress, tombInterface)

const tombCall = call(tombInterface, tombContractAddress)

const useContractState = () => {
    const [totalSupply] =
    useContractCalls([
        tombCall('totalSupply'),
    ]
    ) ?? []
    return {
        totalSupply: totalSupply?.[0],
    }
}

const useTombBalance = makeUseBalance(tombCall)

// const useAccountTokens = (address, balanceValid, library) =>  {
//     const numTokensBN = useTombBalance(address)
//     const numTokens = BigNumber.isBigNumber(numTokensBN) && numTokensBN.toNumber()
//     console.log("useAccountTokens", address, balanceValid, numTokens)
//     const calls = (balanceValid && address && numTokens > 0) ? [...Array(numTokens).keys()].map(i => tombCall('tokenOfOwnerByIndex', [address, i])) : []
//     console.log("calls.length", calls.length)
//     const [c1 = [], c2 = [], c3 = [], c4 = [], c5 = []] = _.chunk(calls, 50)
//     console.log("c1.length", c1.length)
//     console.log("c2.length", c2.length)
//     console.log("c3.length", c3.length)
//     console.log("c4.length", c4.length)
//     console.log("c5.length", c5.length)
    
//     const tombContractLib = new Contract(tombContractAddress, tombInterface, library)
    
//     const calls2 = (balanceValid && address && numTokens > 0) ? [...Array(numTokens).keys()].map(i => {
//         return tombContractLib.tokenOfOwnerByIndex(address, i)
//     }) : []
//     Promise.all(calls2).then(console.log)
//     const ct1 = useContractCalls([
//         ...c1
//     ]).filter(Boolean) || []
//     console.log("c1.length", c1.length)
//     console.log("ct1.length", ct1.length)
//     const ct2 = useContractCalls([
//         ...c2
//     ]).filter(Boolean) || []
//     console.log("c2.length", c2.length)
//     console.log("ct2.length", ct2.length)
//     const ct3 = useContractCalls([
//         ...c3
//     ]).filter(Boolean) || []
//     console.log("c3.length", c3.length)
//     console.log("ct3.length", ct3.length)
//     // const ct4 = useContractCalls([
//     //     ...c4
//     // ]).filter(Boolean) || []
//     // const ct5 = useContractCalls([
//     //     ...c5
//     // ]).filter(Boolean) || []
//     const tokens = [
//         ...ct1,
//         ...ct2,
//         ...ct3,
//         // ...ct4,
//         // ...ct5
//     ].filter(Boolean).map(([t]) => BigNumber.isBigNumber(t) ? t.toNumber() : t)
//     console.log("tokens", tokens)
//     return tokens
// }

const useIsVaultApproved = (address) => {
    const [isApproved] = useContractCalls([
        address && tombCall('isApprovedForAll', [address, vaultContractAddress])
    ])
    return isApproved?.[0]
}

const useApproveVault = () => {
    const fn = useContractFunction(tombContract, 'setApprovalForAll')
    return { setApprovalForAll: () => fn.send(vaultContractAddress, true), setApprovalForAllState: fn.state, setApprovalForAllEvents: fn.events }
}

export { useContractState, useTombBalance, useIsVaultApproved, useApproveVault }