import { useState, useEffect } from 'react'
import { BigNumber, utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { useConfig, useEthers, useBlockNumber } from '@usedapp/core'
import {
    useContractState,
    useTombBalance,
    useKlubBalance,
    useVaultBalance,
    useIsVaultApproved,
    useApproveVault,
    useAccountStakedTokens,
    useEarningInfo,
    useStakeTokens,
    useUnstakeTokens,
    useClaimTokens
} from '../../hooks'
import tomb from '../../hooks/abis/Tombstone.json'
import { tombContractAddress } from '../../config'
import { tokenDataFromIds } from '../../metadata'
import { Transactions } from '../notifications/Transactions'
import Tokens from './Tokens'
import Stats from './Stats'

global.Buffer = global.Buffer || require('buffer').Buffer

function Main() {

    const { activateBrowserWallet, account , library} = useEthers()
    // const account = "0x104EaCc7EE68Ce873e88a55266489bF8F7F49d08"
    const { networks } = useConfig()
    const blockNumber = useBlockNumber()
    const tombBalance = useTombBalance(account)
    const [balanceValid, setBalanceValid] = useState(false)
    const klubBalance = useKlubBalance(account)
    const vaultBalance = useVaultBalance(account)
    const isVaultApproved = useIsVaultApproved(account)
    const [accountTokens, setAccountTokens] = useState([])
    useEffect(() => {
        const numTokens = BigNumber.isBigNumber(tombBalance) && tombBalance.toNumber()
        const tombInterface = new utils.Interface(tomb.abi)
        const tombContractLib = new Contract(tombContractAddress, tombInterface, library)
    
        const calls2 = (balanceValid && account && numTokens > 0) ? [...Array(numTokens).keys()].map(i => {
            return tombContractLib.tokenOfOwnerByIndex(account, i)
        }) : []
        Promise.all(calls2).then(tids => {
            setAccountTokens(tids.map(t => BigNumber.isBigNumber(t) ? t.toNumber() : t))
        })
    }, [account, balanceValid, library, tombBalance])

    const stakedTokens = useAccountStakedTokens(account)
    const allTokens = tokenDataFromIds({ ids: accountTokens, isStaked: false }).concat(
        tokenDataFromIds({ ids: stakedTokens, isStaked: true })).sort(
            (a, b) => Number.parseInt(a.id) > Number.parseInt(b.id) ? 1 : -1)
    const earned = useEarningInfo(stakedTokens, balanceValid)

    const { setApprovalForAll } = useApproveVault()
    const { stake } = useStakeTokens()
    const stakeTokens = async (tokenIds) => {
        await stake(tokenIds).then(() => {
            setBalanceValid(false)
        }).catch((e) => console.log("stake error", e))
    }
    const stakeToken = async (tokenId) => stakeTokens([tokenId])

    const { unstake } = useUnstakeTokens()
    const unstakeTokens = async (tokenIds) => {
        await unstake(tokenIds).then(() => {
            setBalanceValid(false)
        }).catch((e) => console.log("unstake error", e))
    }
    const unstakeToken = async (tokenId) => unstakeTokens([tokenId])

    const { claim } = useClaimTokens()
    const claimTokens = async (tokenIds) => {
        await claim(tokenIds).then(() => {
            setBalanceValid(false)
        }).catch((e) => console.log("claim error", e))
    }
    const claimToken = async (tokenId) => claimTokens([tokenId])

    window.stake = stakeToken
    window.unstake = unstakeToken
    window.claim = claimToken

    const [selectedTokens, setSelectedTokens] = useState([])
    const [stakedTokensSelected, setStakedTokensSelected] = useState([])
    const [unstakedTokensSelected, setUnstakedTokensSelected] = useState([])

    const depString = JSON.stringify([JSON.stringify(selectedTokens), JSON.stringify(accountTokens), JSON.stringify(stakedTokens)])
    useEffect(() => {
        if (selectedTokens && selectedTokens.length > 0) {
            const selectedTokensSet = new Set(selectedTokens)
            if (accountTokens && accountTokens.length > 0) {
                setUnstakedTokensSelected([...accountTokens.map(id => BigNumber.isBigNumber(id) ? id.toNumber() : id).filter(id => selectedTokensSet.has(id))])
            }
            if (stakedTokens && stakedTokens.length > 0) {
                setStakedTokensSelected([...stakedTokens.map(id => BigNumber.isBigNumber(id) ? id.toNumber() : id).filter(id => selectedTokensSet.has(id))])
            }
        } else {
            setUnstakedTokensSelected([])
            setStakedTokensSelected([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depString])

    const [errorMessage, setErrorMessage] = useState()
    const { totalSupply } = useContractState()

    // useEffect(() => {
    //     console.log("selectedTokens", selectedTokens, allTokens)
    //     console.log("stakedTokens, accountTokens", stakedTokens, accountTokens)
    // }, [allTokens, selectedTokens, stakedTokens, accountTokens])


    useEffect(() => {
        if (tombBalance) {
            console.log("new dask balance", tombBalance && tombBalance.toString())
            setBalanceValid(true)
        }
    }, [tombBalance])

    useEffect(() => {
        console.log(blockNumber, tombBalance, accountTokens, klubBalance, vaultBalance, isVaultApproved, totalSupply)
    }, [blockNumber, tombBalance, accountTokens, klubBalance, vaultBalance, isVaultApproved, totalSupply])

    useEffect(() => {
        window.ethereum && window.ethereum.on('chainChanged', (chainId) => {
            if (chainId !== '0x' + networks[0].chainId) {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
            }
        })
    }, [networks])

    const onConnectWallet = async () => {
        setErrorMessage()
        activateBrowserWallet(async (er) => {
            if (er) {
                console.log(er)
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
                activateBrowserWallet()
            }
        }, false)
    }

    return (
        <main className="bg-white mx-auto flex rounded-xl py-6 px-4 my-8 drop-shadow flex-col items-center h-full">
            {!totalSupply && <div className="absolute z-10 w-full h-full backdrop-blur-sm"></div>}
            <form className="w-full">
                {!account &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={onConnectWallet}>Connect Wallet</button>}
                {account &&
                    <div>
                        <Stats blockNumber={blockNumber}
                            tombBalance={tombBalance}
                            vaultBalance={vaultBalance}
                            klubBalance={klubBalance}
                            earned={earned || "0"}
                            // earnRatePerDay={earnRatePerSecond && earnRatePerSecond.mul(24 * 60 * 60)}
                            isVaultApproved={Boolean(isVaultApproved)}
                        />
                        {!isVaultApproved && <button type="button" className="w-full px-5 py-3 mb-8 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={() => setApprovalForAll()}>Approve Vault</button>}

                        {isVaultApproved && ((accountTokens && accountTokens.length > 0) || (stakedTokens && stakedTokens.length > 0)) &&
                            <div>
                                <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-5">
                                    <button type="button" className="w-full/2 px-5 py-3 font-semibold text-center text-white bg-emerald-500 rounded hover:bg-emerald-600 active:bg-emerald-500 disabled:bg-gray-300 disabled:cursor-default" disabled={selectedTokens.length === (accountTokens.length + stakedTokens.length)} onClick={() => setSelectedTokens([...accountTokens, ...stakedTokens])}>Select All</button>
                                    <button type="button" className="w-full/2 px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-gray-300 disabled:cursor-default" disabled={!isVaultApproved || unstakedTokensSelected.length === 0} onClick={async () => await stakeTokens(unstakedTokensSelected).catch((e) => console.log("multi stake error", e))}>Stake Selected ({unstakedTokensSelected.length})</button>
                                    <button type="button" className="w-full/2 px-5 py-3 font-semibold text-center text-white bg-cyan-500 rounded hover:bg-cyan-600 active:bg-cyan-500 disabled:bg-gray-300 disabled:cursor-default" disabled={!isVaultApproved ||stakedTokensSelected.length === 0} onClick={async () => await unstakeTokens(stakedTokensSelected).catch((e) => console.log("multi unstake error", e))}>Claim $KLUB and Unstake Selected ({stakedTokensSelected.length})</button>
                                    <button type="button" className="w-full/2 px-5 py-3 font-semibold text-center text-white bg-cyan-500 rounded hover:bg-cyan-600 active:bg-cyan-500 disabled:bg-gray-300 disabled:cursor-default" disabled={!isVaultApproved ||stakedTokensSelected.length === 0} onClick={async () => await claimTokens(stakedTokensSelected).catch((e) => console.log("multi claim error", e))}>Claim $KLUB from Selected ({stakedTokensSelected.length})</button>
                                    <button type="button" className="w-full/2 px-5 py-3 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled={selectedTokens.length === 0} onClick={() => setSelectedTokens([])}>Clear Selection ({selectedTokens.length})</button>
                                </div>
                                <Tokens selectedTokens={selectedTokens} setSelectedTokens={setSelectedTokens} tokens={allTokens} stake={stakeToken} unstake={unstakeToken} claim={claimToken} />
                            </div>
                        }
                    </div>
                }
            </form>
            <span className="mt-3 text-gray-500" style={{ fontFamily: "RobotoMedium" }}>Current block: {blockNumber}</span>
            <div className='w-full mt-3 text-gray-500' style={{ fontFamily: "RobotoMedium" }}>
                {errorMessage && <h5 className="text-pink-800">{errorMessage}</h5>}
                <div className='w-full text-blue-400'>{<Transactions />}</div>
            </div>
        </main>
    )
}


export default Main;