import { ChainId } from '@usedapp/core'
import { Mainnet, Rinkeby } from '@usedapp/core'

const SupportedChain = Mainnet

const config = {
  networks: [SupportedChain],
  readOnlyChainId: SupportedChain.chainId,
  readOnlyUrls: {
    [ChainId.Mainnet]: 'https://eth-mainnet.alchemyapi.io/v2/j-laCoDEuVRQkXht0rsTRRhogpfwXG5t',
    [ChainId.Rinkeby]: 'https://rinkeby.infura.io/v3/4b72c849071a41dbaf5eee4355478564',
  },
  multicallAddresses: {
    [ChainId.Mainnet]: Mainnet.multicallAddress,
    [ChainId.Rinkeby]: Rinkeby.multicallAddress,
  }
}

const tombContractAddress = '0x40f8719f2919a5DEDD2D5A67065df6EaC65c149C'
const klubContractAddress = '0xa0DB234a35AaF919b51E1F6Dc21c395EeF2F959d'
const vaultContractAddress = '0xDe72166d3b1219f2c65cF4b5A596fd0FE615Ad90'

export { config, tombContractAddress, klubContractAddress, vaultContractAddress }
