import clsx from 'clsx'
import { BigNumber } from 'ethers'
import { tombContractAddress } from '../../config'

const TokenCard = ({ toggleSelected, isSelected, token, stake, unstake, claim }) => {
    return (
        <div id={token.id} className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

            {/* <!-- Article --> */}
            <article className={clsx("overflow-hidden rounded-lg shadow-lg", isSelected && "shadow-cyan-600 border-cyan-500 border-2")}>
                <div className="container relative" onClick={() => toggleSelected(token.id)}>
                    <img alt={"image of " + token.name} className="block h-auto w-full" src={"/Hnet-image_1.gif"} />
                    <input className="absolute top-1 right-2 form-check-input h-4 w-4 border-2 border-gray-300 rounded-sm bg-white checked:bg-cyan-600 checked:border-gray-300 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer" type="checkbox" value="" checked={isSelected} onChange={()=>{}} />
                </div>
                <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 className="text-lg">
                        <span className="no-underline hover:underline text-black">
                            {token.name}
                        </span>
                    </h1>
                    <p className="text-neutral-400 text-xs">
                        <a target="_blank" rel="noreferrer" href={"https://opensea.io/assets/" + tombContractAddress +"/" + token.id}>
                            <img className="opacity-40 hover:opacity-100" src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png" width="20" height="20" alt={"Image of " + token.name} />
                        </a>
                    </p>
                </header>

                <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                    {!token.isStaked && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={async () => await stake(Number.parseInt(token.id)).catch((e) => console.log("stake error", e))}>Stake</button>}
                    {token.isStaked && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-cyan-500 rounded hover:bg-cyan-600 active:bg-cyan-500 disabled:bg-cyan-300 disabled:cursor-default" onClick={async () => await unstake(Number.parseInt(token.id)).catch((e) => console.log("unstake error", e))}>Claim $KLUB and Unstake</button>}
                    {/* {token.isStaked && <button type="button" className="w-full/2 px-5 py-3 font-semibold text-center text-white bg-emerald-500 rounded hover:bg-emerald-600 active:bg-emerald-500 disabled:bg-emerald-300 disabled:cursor-default" onClick={async () => await claim(Number.parseInt(token.id)).catch((e) => console.log("claim error", e))}>Claim $KLUB</button>} */}
                </footer>

            </article>
            {/* <!-- END Article --> */}

        </div>
    )
}

const Tokens = ({ selectedTokens, setSelectedTokens, tokens, stake, unstake, claim }) => {
    const isTokenSelected = (tokenId) => {
        const tokenIdNum = BigNumber.isBigNumber(tokenId) ? tokenId.toNumber() : tokenId
        return selectedTokens.includes(tokenIdNum)
    }
    const toggleSelected = (tokenId) => {
        const tokenIdNum = BigNumber.isBigNumber(tokenId) ? tokenId.toNumber() : tokenId
        if (isTokenSelected(tokenIdNum)) {
            setSelectedTokens([...selectedTokens.filter((t) => t !== tokenIdNum)])
        } else {
            setSelectedTokens([...selectedTokens, tokenIdNum])
        }
    }
    return (
        <div className="container my-12 mx-auto px-4 md:px-12">
            <div className="flex flex-wrap -mx-1 lg:-mx-4">
                {tokens && tokens.map((token, idx) => (
                    <TokenCard toggleSelected={toggleSelected} isSelected={isTokenSelected(token.id)} token={token} key={idx} stake={stake} unstake={unstake} claim={claim} />
                ))}
            </div>
        </div>
    )
}



export default Tokens;